import "./index.css"

import React, { useEffect, useRef, useState } from "react"

import min from "lodash/min"
import some from "lodash/some"
import SimpleReactLightbox from "simple-react-lightbox"
import times from "lodash/times"

import SEO from "../components/seo"
import { Menu } from "../components/menu"
import { SRLWrapper } from "simple-react-lightbox"
import { useInitialLoad } from "../hooks/use-initial-load"

const Image = ({ index, loadMore }) => {
  const [isLoading, setIsLoading] = useState(true)
  const [show, setShow] = useState(false)
  const initialLoadCompleted = useInitialLoad()
  const observer = useRef()
  const ref = useRef()

  const shouldShow = !isLoading && initialLoadCompleted && show

  useEffect(() => {
    setTimeout(() => {
      setShow(true)
    }, Math.floor(Math.random() * 10) * 100)
  })

  useEffect(() => {
    if (!loadMore || !shouldShow) {
      return
    }

    observer.current = new IntersectionObserver(
      (targets) => {
        if (some(targets, (target) => target.isIntersecting)) {
          loadMore()
        }
      },
      {
        threshold: 0.1,
      },
    )

    if (ref.current) {
      observer.current.observe(ref.current)
    }

    return () => {
      observer.current.disconnect()
    }
  }, [loadMore, shouldShow])

  const pic = String(index).padStart(3, "0")

  return (
    <a className="thumbnail" href={`https://we-are-abel.s3-us-west-2.amazonaws.com/weareabel-${pic}.jpg`}>
      <img
        alt="We Are Abel"
        className="animate-opacity"
        onLoad={() => {
          setIsLoading(false)
        }}
        ref={ref}
        src={`https://we-are-abel.s3-us-west-2.amazonaws.com/previews/weareabel-${pic}-thumb.jpg`}
        style={{
          opacity: shouldShow ? 1 : 0,
        }}
      />
    </a>
  )
}

const options = {
  buttons: {
    showDownloadButton: false,
  },
  caption: {
    showCaption: false,
  },
}

const Content = () => {
  const [count, setCount] = useState(10)

  const bumpCount = () => {
    setCount(min([count + 10, 270]))
  }

  return (
    <SRLWrapper options={options}>
      <div
        className="gallery"
        style={{
          display: "flex",
          flexDiretion: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {times(count).map((index) => (
          <Image
            index={index}
            key={index}
            loadMore={(
              index === count - 1
              && count < 270
              && bumpCount
            )}
          />
        ))}
        <div
          className="animate-opacity text"
          style={{
            fontSize: "32px",
            margin: "24px 0px 0px 0px",
            opacity: count === 270 ? 1 : 0,
            textAlign: "center",
            width: "100%",
          }}
        >
          Thank you for sharing this day with us!
        </div>
      </div>
    </SRLWrapper>
  )
}

const Page = (props) => {
  return (
    <>
      <Menu location={props.location}/>
      <Content/>
      <div className="container overlay"/>
    </>
  )
}

const Gallery = (props) => (
  <SimpleReactLightbox>
    <SEO title="Gallery"/>
    <Page {...props}/>
  </SimpleReactLightbox>
)

export default Gallery
